import { ReactNode } from 'react'
import IconLists from '@/helpers/IconLists'
import { StaticImageData } from 'next/image'
import { ParsedContent } from '@/types/global'

import { ButtonWithClasses, parsedContent } from '@/lib/utils'
// invoice-generator images
import invoiceImg from '~/public/assets/images/invoice-generator/invoice.png'
import invoiceViaText from '~/public/assets/images/invoice-generator/invoice-via-text.png'
import invoiceViaEmail from '~/public/assets/images/invoice-generator/invoice-via-email.png'
import collectMoney from '~/public/assets/images/invoice-generator/how-to-collect-money.png'
import editInvoice from '~/public/assets/images/invoice-generator/edit-invoice.png'
import addLogo from '~/public/assets/images/invoice-generator/add-logo.png'
import addStamp from '~/public/assets/images/invoice-generator/add-stamp.png'

import addSignature from '~/public/assets/images/invoice-generator/add-signature.png'
// online-invoice-generator images
import OnlineInvoice from '~/public/assets/images/online-invoice-generator/tab-images/online-invoices.png'
import TextToPay from '~/public/assets/images/online-invoice-generator/tab-images/Text-to-Pay.png'
import PaymentLink from '~/public/assets/images/online-invoice-generator/tab-images/PaymentLink.png'
import CustomInvoice from '~/public/assets/images/online-invoice-generator/tab-images/CustomInvoice.png'

import InvoiceTemplate from '~/public/assets/images/online-invoice-generator/tab-images/InvoiceTemplate.png'
import PaymentImg from '~/public/assets/images/homepage/tab/payment-management.png'
import RiskImg from '~/public/assets/images/homepage/tab/risk-management.png'
import FlexibilityImg from '~/public/assets/images/homepage/tab/flexibility.png'
import ComplianceImg from '~/public/assets/images/homepage/tab/compliance.png'

import TechnologyImg from '~/public/assets/images/homepage/tab/technology.png'
// POS SYSTEM Images
import PosTabThumb1 from '~/public/assets/images/pos-system/hassle-free-sales.png'
import PosTabThumb2 from '~/public/assets/images/pos-system/efficient-inventory.png'
import PosTabThumb3 from '~/public/assets/images/pos-system/supporting-your-staff.png'

import PosTabThumb4 from '~/public/assets/images/pos-system/streamlined-operations.png'
// PayFac Images
import virtualDebitCardImg from '~/public/assets/images/payfac/pfaas-our-solution/virtual-debit-card.png'
import invoiceManagementImg from '~/public/assets/images/payfac/pfaas-our-solution/invoice-management.png'
import seamlessImg from '~/public/assets/images/payfac/pfaas-our-solution/seamless.png'
import securedPaymentImg from '~/public/assets/images/payfac/pfaas-our-solution/secured-payment.png'

import reportingAndAnalyticsImg from '~/public/assets/images/payfac/pfaas-our-solution/reporting-and-analytics.png'
// Free Invoice Creation Images
import FreeInvoiceCreation1 from '~/public/assets/images/free-invoice-creation/solution.png'
import FreeInvoiceCreation2 from '~/public/assets/images/free-invoice-creation/innovation.png'
import FreeInvoiceCreation3 from '~/public/assets/images/free-invoice-creation/customisation.png'
import FreeInvoiceCreation4 from '~/public/assets/images/free-invoice-creation/security.png'

import FreeInvoiceCreation5 from '~/public/assets/images/free-invoice-creation/support.png'
// online-invoice-software
import isw_sign from '~/public/assets/images/online-invoice-software/invoice-software-works/sign.png'
import isw_customizeProfile from '~/public/assets/images/online-invoice-software/invoice-software-works/customize-profile.png'
import isw_createInvoices from '~/public/assets/images/online-invoice-software/invoice-software-works/create-invoices.png'
import isw_addPaymentOptions from '~/public/assets/images/online-invoice-software/invoice-software-works/add-payment-options.png'

import isw_sendInvoices from '~/public/assets/images/online-invoice-software/invoice-software-works/send-invoices.png'
// Free Invoice Creation Images
import OnlineInvoicePayment1 from '~/public/assets/images/online-invoice-payment/startup.png'
import OnlineInvoicePayment2 from '~/public/assets/images/online-invoice-payment/retailer.png'
import OnlineInvoicePayment3 from '~/public/assets/images/online-invoice-payment/consultant.png'
import OnlineInvoicePayment4 from '~/public/assets/images/online-invoice-payment/subscription.png'

import OnlineInvoicePayment5 from '~/public/assets/images/online-invoice-payment/nonprofit.png'
// Online Invoicing Images
import OnlineInvoicing1 from '~/public/assets/images/online-invoicing/integrated.png'
import OnlineInvoicing2 from '~/public/assets/images/online-invoicing/simplify.png'
import OnlineInvoicing3 from '~/public/assets/images/online-invoicing/unified.png'
import OnlineInvoicing4 from '~/public/assets/images/online-invoicing/mobile.png'
import OnlineInvoicing5 from '~/public/assets/images/online-invoicing/secure.png'
import OnlineInvoicing6 from '~/public/assets/images/online-invoicing/support.png'
import OnlineInvoicing7 from '~/public/assets/images/online-invoicing/constant.png'

import OnlineInvoicing8 from '~/public/assets/images/online-invoicing/pricing.png'
// New Homepage Images
import NewHomepage1 from '~/public/assets/images/homepage/v4/business-merchant.svg'
import NewHomepage2 from '~/public/assets/images/homepage/v4/business-finance.svg'

import NewHomepage3 from '~/public/assets/images/homepage/v4/business-platform.svg'
// Account Payable Services
import AccountPayableService1 from '~/public/assets/images/accounts-payable-services/start-guide.png'
import AccountPayableService2 from '~/public/assets/images/accounts-payable-services/software-use.png'

import AccountPayableService3 from '~/public/assets/images/accounts-payable-services/process-opt.png'
// Text to pay page images
import TextToPayImage1 from '~/public/assets/images/text-2-pay/v4/benefits.png'
import TextToPayImage2 from '~/public/assets/images/text-2-pay/v4/usecases.png'
import TextToPayImage3 from '~/public/assets/images/text-2-pay/v4/fees.png'

import benefits from '~/public/assets/images/online-checkbook/need-to-know/benefits.png'
import useCases from '~/public/assets/images/online-checkbook/need-to-know/use-cases.png'
import sendDigitalChecks from '~/public/assets/images/online-checkbook/need-to-know/send-digital-checks.png'

/* Online Payments */
import Security from '~/public/assets/images/pages/online-payments/security.png'
import Convenience from '~/public/assets/images/pages/online-payments/convenience.png'
import Support from '~/public/assets/images/pages/online-payments/support.png'
import basicsOfBank from '~/public/assets/images/bank-account-verification/basics-of-bank/basics-of-bank.png'
import instantAutoBank from '~/public/assets/images/bank-account-verification/basics-of-bank/instant-and-auto-bank.png'
import microDeposit from '~/public/assets/images/bank-account-verification/basics-of-bank/micro-deposit.png'

/*Online Invoicing*/
import DetailedReport from '~/public/assets/images/pages/online-invoicing/detailed-report.png'
import InvPaymentLink from '~/public/assets/images/pages/online-invoicing/payment-link.png'
import InvoiceForSmallBusiness from '~/public/assets/images/pages/online-invoicing/invoice-for-small-business.png'

/*kyc-verification*/
import UnmatchedEfficiency from '~/public/assets/images/kyc-verifications/unmatched-efficiency.png'
import enhancedSecurity from '~/public/assets/images/kyc-verifications/enhanced-security.png'
import costEffective from '~/public/assets/images/kyc-verifications/cost-effective.png'

/*kyb-verification*/
import businessVerification from '~/public/assets/images/kyb-verifications/everything-you-need/business-verification-definition.png'
import benefitsOfKyb from '~/public/assets/images/kyb-verifications/everything-you-need/benefits-of-kyb-verifications.png'
import verifyFromOriginal from '~/public/assets/images/kyb-verifications/everything-you-need/verify-from-original-source.png'

/*CreditCardTerminal*/
import creditCardTerminal1 from '~/public/assets/images/credit-card-terminal/secure-user.svg'
import creditCardTerminal2 from '~/public/assets/images/credit-card-terminal/transparent-pricing.svg'
import creditCardTerminal3 from '~/public/assets/images/credit-card-terminal/solution-integration.svg'

/*identity-verification*/
import streamlinedCompliance from '~/public/assets/images/identity-verification/efficient-solution/streamlined-compliance-measures.png'
import enhancedSecurityMeasures from '~/public/assets/images/identity-verification/efficient-solution/enhanced-security-measures.png'
import customerCentricApproach from '~/public/assets/images/identity-verification/efficient-solution/customer-centric-approach.png'

interface TabDetail {
  icon?: string | ReactNode
  title?: ParsedContent
  content?: ParsedContent
  button?: {
    text?: string
    href?: string
  }
}

export type TabContent = {
  value?: number
  altTxt?: string
  detail?: TabDetail[]
  title?: ParsedContent
  img?: StaticImageData
  content?: ParsedContent
  label?: string | ParsedContent
  detail_content?: ParsedContent
}

const divClass =
  'lg:mt-[100px] [&_p]:!text-twilight [&_p]:text-[15px] [&_p]:leading-[22px] lg:[&_p]:text-xl lg:[&_p]:leading-[28px] max-w-[597px]'

type SectionKey =
  | 'home'
  | 'retails-pos-system'
  | 'online-invoice-generator'
  | 'invoice-generator'
  | 'payfac-as-a-service'
  | 'free-invoice-creator'
  | 'online-invoice-software'
  | 'online-invoice-payment'
  | 'online-invoice'
  | 'online-invoicing'
  | 'homepage_new'
  | 'accounts_payable_services'
  | 'text_to_pay'
  | 'online-checkbook'
  | 'online-payments'
  | string

export const SECTION_TABS_DATA: {
  [key: SectionKey]: TabContent[]
} = {
  ['home']: [
    {
      label: 'Payments Management',
      detail_content: parsedContent(`
       <div class="mt-0 lg:mt-[34px] [&_p]:!text-twilight lg:[&_p]:!text-xl max-w-[650px]">
        <h2 class="text-xl lg:text-[48px] leading-[30px] lg:leading-[56px] text-[#020626] font-bold mb-[10px] lg:mb-6">Effortless Payment Processing</h2>
        <p class="mb-2 lg:mb-9">With PayToMe, you can seamlessly handle <a href="/all-in-one-payment-solution" target="_blank" style="color: #0d8ee9">payment processing</a>, allowing you to accept various payment methods, manage transactions, and ensure secure and efficient payment flows.</p>
      </div>`),
      img: PaymentImg
    },
    {
      label: 'Risk Management',
      detail_content: parsedContent(`
       <div class="mt-0 lg:mt-[34px] [&_p]:!text-twilight lg:[&_p]:!text-xl max-w-[650px]">
        <h2 class="text-xl lg:text-[48px] leading-[30px] lg:leading-[56px] text-[#020626] font-bold mb-[10px] lg:mb-6">Ensuring Risk-Free Transactions</h2>
        <p class="mb-2 lg:mb-9">PayToMe incorporates robust risk management solutions to identify and mitigate potential risks associated with financial transactions, ensuring the security and integrity of your operations.</p>
      </div>`),
      img: RiskImg
    },
    {
      label: 'Scalability & Flexibility',
      detail_content: parsedContent(`
       <div class="mt-0 lg:mt-[34px] [&_p]:!text-twilight lg:[&_p]:!text-xl max-w-[650px]">
        <h2 class="text-xl lg:text-[48px] leading-[30px] lg:leading-[56px] text-[#020626] font-bold mb-[10px] lg:mb-6">Adaptable Infrastructure for Business Growth</h2>
        <p class="mb-2 lg:mb-9">PayToMe's infrastructure is designed to be highly scalable and flexible, accommodating your business growth and evolving needs. Whether you're expanding your customer base or launching new products, PayToMe can adapt to your changing requirements.</p>
      </div>`),
      img: FlexibilityImg
    },
    {
      label: 'Compliance Support',
      detail_content: parsedContent(`
       <div class="mt-0 lg:mt-[34px] [&_p]:!text-twilight lg:[&_p]:!text-xl max-w-[650px]">
        <h2 class="text-xl lg:text-[48px] leading-[30px] lg:leading-[56px] text-[#020626] font-bold mb-[10px] lg:mb-6">Guidance for Regulatory Compliance</h2>
        <p class="mb-2 lg:mb-9">Fast-track your FinTech success with PayToMe’s comprehensive compliance program. In partnership with our sponsor banks, we provide a solid foundation for FinTechs to build, launch, and scale their offerings quickly. With our expert guidance and robust governance framework, you can confidently navigate the complex landscape of compliance requirements.</p>
      </div>`),
      img: ComplianceImg
    },
    {
      label: 'Advanced Technology',
      detail_content: parsedContent(`
       <div class="mt-0 lg:mt-[34px] [&_p]:!text-twilight lg:[&_p]:!text-xl max-w-[650px]">
          <h2 class="text-xl lg:text-[48px] leading-[30px] lg:leading-[56px] text-[#020626] font-bold mb-[10px] lg:mb-6">Cutting-edge Solutions for FinTech Success</h2>
          <p class="mb-2 lg:mb-9">PayToMe leverages cutting-edge technology to provide you with a robust and reliable infrastructure. This includes advanced security measures, data analytics capabilities, and integration with industry-standard tools and systems.</p>
        </div>`),
      img: TechnologyImg
    }
  ],
  ['retails-pos-system']: [
    {
      label: 'Hassle Free Sales',
      detail_content: parsedContent(`
       <div class="mt-0 lg:mt-[34px] [&_p]:!text-twilight lg:[&_p]:!text-xl max-w-[650px]">
        <h2 class="text-[22px] hidden lg:!block lg:text-[32px] leading-[30px] lg:leading-[33px] text-[#020626] font-bold mb-[10px] lg:mb-6">Hassle Free Sales</h2>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Accept Payments Anywhere:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            With PayToMe, accept payments both in-store and online.
          </p>
        </div>
        <div class='content-item'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Effortless Returns and Exchanges:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Our system allows you to accept returns at any location and process exchanges seamlessly.
          </p>
        </div>
      </div>`),
      img: PosTabThumb1
    },
    {
      label: 'Efficient Inventory',
      detail_content: parsedContent(`
       <div class="mt-0 lg:mt-[34px] [&_p]:!text-twilight lg:[&_p]:!text-xl max-w-[650px]">
        <h2 class="text-[22px] hidden lg:!block lg:text-[32px] leading-[30px] lg:leading-[33px] text-[#020626] font-bold mb-[10px] lg:mb-6">Efficient Inventory</h2>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Never Run Out of Stock:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Our POS machine keeps you informed with low-stock alerts and even sends automatic purchase orders to your vendors.
          </p>
        </div>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Streamlined Inventory Counts:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Utilize our barcode scanner to update stock counts on the fly. Plus, you can print barcodes with ease.
          </p>
        </div>
        <div class='content-item'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Flexible Fulfillment Options:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Give your customers the freedom to choose between in-store pickup or convenient shipping for their orders.
          </p>
        </div>
      </div>`),
      img: PosTabThumb2
    },
    {
      label: 'Supporting Your Staff',
      detail_content: parsedContent(`
       <div class="mt-0 lg:mt-[34px] [&_p]:!text-twilight lg:[&_p]:!text-xl max-w-[650px]">
        <h2 class="text-[22px] hidden lg:!block lg:text-[32px] leading-[30px] lg:leading-[33px] text-[#020626] font-bold mb-[10px] lg:mb-6">Supporting Your Staff</h2>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Know Your Customers:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Take notes, send direct messages, and automatically track spending habits.
          </p>
        </div>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Drive Customer Loyalty:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Keep customers coming back with targeted marketing campaigns that promote your latest events, offers, and products.
          </p>
        </div>
        <div class='content-item'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Efficient Team Management:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            PayToMe.co makes it simple to create schedules, set permissions, and track employee time on a user-friendly POS system.
          </p>
        </div>
      </div>`),
      img: PosTabThumb3
    },
    {
      label: 'Streamlined Operations',
      detail_content: parsedContent(`
       <div class="mt-0 lg:mt-[34px] [&_p]:!text-twilight lg:[&_p]:!text-xl max-w-[650px]">
        <h2 class="text-[22px] hidden lg:!block lg:text-[32px] leading-[30px] lg:leading-[33px] text-[#020626] font-bold mb-[10px] lg:mb-6">Streamlined Operations</h2>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Seamless Integrations:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
          Connect all your essential tools like QuickBooks and WooCommerce directly into your POS software, consolidating your operations in one place.
          </p>
        </div>
        <div class='content-item'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Actionable Insights:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Gain valuable insights into your business with detailed sales, profit, and sell-through reports. Understand what drives your revenue and make informed decisions.
          </p>
        </div>
      </div>`),
      img: PosTabThumb4
    }
  ],
  ['online-invoice-generator']: [
    {
      label: 'Online Invoice',
      detail_content: parsedContent(`
       <div class="${divClass}">
        <h2 class="text-[48px] text-[#020626] font-bold mb-4 hidden lg:!block">Online Invoice</h2>
        <p class="mb-3 lg:mb-7">PayToMe’s digital invoices offer a modern solution to traditional billing methods.</p>
        <p class="mb-3 lg:mb-4">With our advanced invoice generator, you can create, send, and track invoices electronically, eliminating paper-based inefficiencies</p>
        <p>Save time and money with streamlined invoicing.</p>
      </div>`),
      img: OnlineInvoice
    },
    {
      label: 'Text to Pay',
      detail_content: parsedContent(`
       <div class="${divClass}">
        <h2 class="text-[48px] text-[#020626] font-bold mb-4 hidden lg:!block">Text to Pay</h2>
        <p class="mb-3 lg:mb-4">Text to Pay is a convenient payment solution that allows customers to settle their bills via SMS. With just a simple text message, your clients can make payments securely and swiftly, enhancing their overall payment experience.</p>
        <p>PayToMe’s patented text-to-pay technology simplifies payments! Just send an invoice link through SMS!</p>
      </div>`),
      img: TextToPay
    },
    {
      label: 'Payment Link',
      detail_content: parsedContent(`
       <div class="${divClass}">
        <h2 class="text-[48px] text-[#020626] font-bold mb-4 hidden lg:!block">Payment Link</h2>
        <p class="mb-3 lg:mb-4">Payment links are a hassle-free way to request payments from your customers. Text payment link via email or messaging apps, and your clients can make payments with ease, from anywhere and any time!</p>
        <p>PayToMe’s patented text-2-pay technology allows you to send direct payment link to your clients.</p>
      </div>`),
      img: PaymentLink
    },
    {
      label: 'Custom Invoice',
      detail_content: parsedContent(`
       <div class="${divClass}">
        <h2 class="text-[48px] text-[#020626] font-bold mb-4 hidden lg:!block">Custom Invoice</h2>
        <p class="mb-3 lg:mb-4">Our custom invoice templates allow your brand's identity to shine through. Tailor your invoices with personalized designs, incorporating your logo, color scheme, and unique branding elements.</p>
        <p>Explore our custom invoice samples to see how you can make every invoice a reflection of your brand's individuality.</p>
      </div>`),
      img: CustomInvoice
    },
    {
      label: 'Invoice Template',
      detail_content: parsedContent(`
       <div class="${divClass}">
          <h2 class="text-[48px] text-[#020626] font-bold mb-4 hidden lg:!block">Invoice Template</h2>
          <p class="mb-3 lg:mb-4">Our diverse selection of invoice templates by country ensures that your invoices comply with local regulations and are culturally appropriate.</p>
          
        </div>`),
      img: InvoiceTemplate
    }
  ],
  ['invoice-generator']: [
    {
      label: 'How to Use',
      title: parsedContent(
        'How to Use the PayToMe.co Invoice Generator',
        'text-[#314259] lg:text-[32px] lg:leading-[36px] lg:mb-[28px]'
      ),
      content: parsedContent(
        'Efficient and Convenient Invoicing with PayToMe.co Invoice Generator',
        'text-[#004263]  lg:text-2xl lg:leading-[34px] max-w-[649px]'
      ),
      detail: [
        {
          icon: IconLists.addNoteIcon,
          title: parsedContent('Create a New Invoice'),
          content: parsedContent(
            'Enter all the necessary information, Company details, client details, invoice date, due date, and items.'
          )
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Customize as Needed'),
          content: parsedContent('Add your company logo, stamp, and signature for a personalized touch.')
        },
        {
          icon: IconLists.previewNoteIcon,
          title: parsedContent('Preview Your Invoice'),
          content: parsedContent('Review the invoice to ensure accuracy.')
        },
        {
          icon: IconLists.sendIcon,
          title: parsedContent('Send'),
          content: parsedContent(
            'You can save the invoice for later or send it directly to your client via text message and email.'
          )
        },
        {
          icon: IconLists.collectPaymentIcon,
          title: parsedContent('Collect Payments'),
          content: parsedContent(`<p>
            If desired, add a payment link for easy and secure payment collection. There are three modes of
            collecting payments
          </p>
          <ul class='list-inside my-[16px] lg:my-[28px] [&_*]:text-[#004263]'>
            <li class='list-disc pl-2 py-0.5'>Add Tips</li>
            <li class='list-disc pl-2 py-0.5'>Split Payments</li>
            <li class='list-disc pl-2 py-0.5'>Installment Payment</li>
          </ul>
          <p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px]'>
            Using the Invoice Generator is a breeze, simplifying your invoicing process and impressing your clients
            with professional invoices.
          </p>
          `)
        }
      ],
      img: invoiceImg,
      altTxt: 'Invoice generator'
    },
    {
      label: 'Invoice via Text',
      title: parsedContent(
        'How to Send Invoices with Text Messaging',
        'text-[#314259] lg:text-[32px] lg:leading-[36px] lg:mb-[28px]'
      ),
      content: parsedContent(
        'Sending Instant Invoice Link Through Text Messaging',
        'text-[#004263]  lg:text-2xl lg:leading-[34px] max-w-[649px]'
      ),
      detail: [
        {
          icon: IconLists.addNoteIcon,
          title: parsedContent('Create Your Invoice'),
          content: parsedContent(
            "Use PayToMe's Invoice Generator to craft your invoice with all the necessary details."
          )
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Add Correct Phone'),
          content: parsedContent("Provide your client's phone number. Make sure you are providing correct number.")
        },
        {
          icon: IconLists.cropIcon,
          title: parsedContent('Save Items'),
          content: parsedContent('Save each item after proper entry.')
        },
        {
          icon: IconLists.submitIcon,
          title: parsedContent('Submit'),
          content: parsedContent(
            `Click "Submit" and you're good to go. Your client will receive the invoice as a text message.`
          )
        }
      ],
      img: invoiceViaText,
      altTxt: 'text to pay'
    },
    {
      label: 'Invoice via Email',
      title: parsedContent(
        'How to Send Invoices with Emails',
        'text-[#314259] lg:text-[32px] lg:leading-[36px] lg:mb-[28px]'
      ),
      content: parsedContent(
        'Send Professional Invoices with Ease through Emails',
        'text-[#004263]  lg:text-2xl lg:leading-[34px] max-w-[649px]'
      ),
      detail: [
        {
          icon: IconLists.addNoteIcon,
          title: parsedContent('Generate Your Invoice'),
          content: parsedContent("Start by creating your invoice using PayToMe's Invoice Generator.")
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent("Enter Recipient's Email Address"),
          content: parsedContent(
            `Input your client's email address. You will get this option while adding “Bill/Service To”`
          )
        },
        {
          icon: IconLists.submitIcon,
          title: parsedContent('Submit'),
          content: parsedContent(
            `Click "Submit" and your client will receive the invoice in their email inbox. It's that simple! Efficient invoicing made easy.`
          )
        }
      ],
      img: invoiceViaEmail,
      altTxt: 'Send Invoices'
    },
    {
      label: 'Collect Money',
      title: parsedContent(
        'How to Collect Money (Add Payment Link)',
        'text-[#314259] lg:text-[32px] lg:leading-[36px] lg:mb-[28px]'
      ),
      content: parsedContent(
        "Effortless Payment Collection with PayToMe.Co's Payment Link Feature",
        'text-[#004263]  lg:text-2xl lg:leading-[34px] max-w-[649px]'
      ),
      detail: [
        {
          icon: IconLists.paymentLinkIcon,
          title: parsedContent('Payment Link Option'),
          content: parsedContent(`Look for the "Add Payment Link" option within the generator.`)
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Specify Payment Method'),
          content: parsedContent(
            "Choose the payment method you prefer or that your client prefers, whether it's credit card, debit card, or bank account."
          )
        },
        {
          icon: IconLists.clientReceiveLinkIcon,
          title: parsedContent('Client Receives Link'),
          content: parsedContent('Your client will receive the invoice along with the payment link.')
        },
        {
          icon: IconLists.securePaymentIcon,
          title: parsedContent('Secure Payment'),
          content:
            parsedContent(`<p class="mb-3">Your client can click the link and make a secure payment directly through the given payment link. </p>
        <p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px] ml-[0] lg:ml-[-32px]'>By adding a payment link, you streamline the payment process, making  it convenient for both you and your clients.</p>
        `)
        }
      ],
      img: collectMoney,
      altTxt: 'invoice payment'
    },
    {
      label: 'Edit Invoice',
      title: parsedContent(
        'Edit Invoice on Invoice Generator',
        'text-[#314259] lg:text-[32px] lg:leading-[36px] lg:mb-[28px]'
      ),
      content: parsedContent(
        "Modify and Update Invoices with PayToMe.Co's Invoice Generator",
        'text-[#004263]  lg:text-2xl lg:leading-[34px] max-w-[649px]'
      ),
      detail: [
        {
          icon: IconLists.addNoteIcon,
          title: parsedContent('Access Your Account'),
          content: parsedContent('Log in to your PayToMe.co account.')
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Locate Your Invoices'),
          content: parsedContent('Find the invoice you want to edit within your dashboard.')
        },
        {
          icon: IconLists.selectEditOptionIcon,
          title: parsedContent('Select Edit Option'),
          content: parsedContent('Click on the "Edit" or "Modify" option associated with the specific invoice.')
        },
        {
          icon: IconLists.makeChangesIcon,
          title: parsedContent('Make Changes'),
          content: parsedContent('Update the necessary information, such as amounts, descriptions, or client details.')
        },
        {
          icon: IconLists.submitIcon,
          title: parsedContent('Save Changes'),
          content: parsedContent('After making your edits, remember to save the changes before proceeding.')
        },
        {
          icon: IconLists.reviewInvoiceIcon,
          title: parsedContent('Review the Invoice'),
          content: parsedContent('Double-check the revised invoice to ensure accuracy.')
        },
        {
          icon: IconLists.resendIcon,
          title: parsedContent('Resend if Needed'),
          content:
            parsedContent(`<p class="mb-3">If you've already sent the invoice to your client, you can choose to resend the updated version.</p>
        <p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px] ml-[0] lg:ml-[-32px]'>Editing invoices with PayToMe's Invoice Generator is straightforward and allows you to keep your records accurate and up-to-date.</p>
        `)
        }
      ],
      img: editInvoice,
      altTxt: 'online invoice'
    },
    {
      label: 'Add LOGO',
      title: parsedContent(
        'Add Your LOGO on Invoice Generator',
        'text-[#314259] lg:text-[32px] lg:leading-[36px] lg:mb-[28px]'
      ),
      content: parsedContent(
        'How to Add Your Logo to Your Invoice: Step-by-Step Guide',
        'text-[#004263]  lg:text-2xl lg:leading-[34px] max-w-[649px]'
      ),
      detail: [
        {
          icon: IconLists.attachIcon,
          title: parsedContent('Upload Your Logo'),
          content: parsedContent(
            'Click the "Upload Logo" button to select and upload your company logo from your device.'
          )
        },
        {
          icon: IconLists.saveChangesIcon,
          title: parsedContent('Save Changes'),
          content: parsedContent("Don't forget to save your changes to ensure your logo appears on future invoices.")
        },
        {
          icon: IconLists.lightModeIcon,
          title: parsedContent('Preview an Invoice'),
          content:
            parsedContent(`<p class="mb-3">Create a new invoice or access an existing one to see your logo elegantly integrated.</p>
        <p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px] ml-[0] lg:ml-[-32px]'>Adding your logo to your invoices adds a professional touch and reinforces your brand identity, making a lasting impression on your clients.</p>
        `)
        }
      ],
      img: addLogo,
      altTxt: 'logo on Invoice'
    },
    {
      label: 'Add Stamp',
      title: parsedContent(
        'Add Your Company Stamp on Invoice Generator',
        'text-[#314259] lg:text-[32px] lg:leading-[36px] lg:mb-[28px]'
      ),
      content: parsedContent(
        'Enhance Your Invoices with Your Company Stamp',
        'text-[#004263]  lg:text-2xl lg:leading-[34px] max-w-[649px]'
      ),
      detail: [
        {
          icon: IconLists.addNoteIcon,
          title: parsedContent('Upload Your Company Stamp'),
          content: parsedContent(
            'Click the "Upload Stamp" or similar button to select and upload an image of your company stamp from your device.'
          )
        },
        {
          icon: IconLists.submitIcon,
          title: parsedContent('Save Changes'),
          content: parsedContent(
            'Make sure to save your settings to ensure your company stamp appears on future invoices.'
          )
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Preview an Invoice'),
          content:
            parsedContent(`<p class="mb-3">Create a new invoice or access an existing one to see your company stamp proudly displayed.</p> 
<p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px] ml-[0] lg:ml-[-32px]'>Incorporating your company stamp into your invoices adds an extra layer of professionalism and authenticity to your billing documents.</p>`)
        }
      ],
      img: addStamp,
      altTxt: 'Invoice Generator'
    },
    {
      label: 'Add Signature',
      title: parsedContent(
        'Add Your Signature On Invoice Generator',
        'text-[#314259] lg:text-[32px] lg:leading-[36px] lg:mb-[28px]'
      ),
      content: parsedContent(
        'Personalize Your Invoices with Your Own Signature',
        'text-[#004263]  lg:text-2xl lg:leading-[34px] max-w-[649px]'
      ),
      detail: [
        {
          icon: IconLists.attachIcon,
          title: parsedContent('Upload Your Signature'),
          content: parsedContent(
            'Click on "Upload Signature" to select and upload an image file of your signature from your device.'
          )
        },
        {
          icon: IconLists.saveChangesIcon,
          title: parsedContent('Save Changes'),
          content: parsedContent(
            'Remember to save your settings to ensure your signature is included on your invoices.'
          )
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Preview an Invoice'),
          content: parsedContent(
            `<p class="mb-3">Create a new invoice or access an existing one to see your personalized signature elegantly integrated.</p><p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px] ml-[0] lg:ml-[-32px]'>Adding your signature to your invoices provides a personal touch and authenticity, enhancing your professional image with clients.</p>`
          )
        }
      ],
      img: addSignature,
      altTxt: 'Signature On Invoice'
    }
  ],
  ['payfac-as-a-service']: [
    {
      label: 'Virtual & Debit Card',
      detail: [
        {
          title: parsedContent(
            'Virtual & Debit Card',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">PFaaS includes virtual card and debit card offerings, enabling merchants to issue cards to their customers for seamless online transactions.</p>
            <p>These cards can be used for one-time purchases or as ongoing payment methods, providing flexibility and convenience.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: virtualDebitCardImg
    },
    {
      label: 'Invoice Management',
      detail: [
        {
          title: parsedContent(
            'Invoice Management',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">PayToMe's PFaaS solution offers comprehensive invoice management capabilities. Businesses can easily generate and send invoices to their customers, track payments, and set up automated reminders for overdue invoices.</p>
            <p>This streamlines the billing process and improves cash flow management.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: invoiceManagementImg
    },
    {
      label: 'Seamless Integration',
      detail: [
        {
          title: parsedContent(
            'Seamless Integration',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">PayToMe's PFaaS solution seamlessly integrates with existing business systems and software.</p>
            <p>We offer a range of APIs and developer tools, making it easy for merchants to connect their websites, mobile apps, or point-of-sale (POS) systems with our payment platform.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: seamlessImg
    },
    {
      label: 'Secured Payment',
      detail: [
        {
          title: parsedContent(
            'Secured Payment',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">Security is a top priority for PayToMe. Our PFaaS solution incorporates robust encryption and data protection measures to ensure the safe handling of sensitive payment information.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: securedPaymentImg
    },
    {
      label: 'Reporting and Analytics',
      detail: [
        {
          title: parsedContent(
            'Reporting and Analytics',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">PFaaS offers robust reporting and analytics tools, providing payment facilitators with insights into transaction volumes, revenue, and other key metrics. These insights help businesses make data-driven decisions and optimize their payment processes.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: reportingAndAnalyticsImg
    }
  ],
  ['free-invoice-creator']: [
    {
      label: 'All-In-One Solution',
      detail: [
        {
          title: parsedContent(
            'All-In-One Solution',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">While many platforms offer invoicing alone, PayToMe.co presents a holistic financial ecosystem, encompassing payment facilitation and Software as a Service (SaaS).</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: FreeInvoiceCreation1
    },
    {
      label: 'AI-Powered Innovation',
      detail: [
        {
          title: parsedContent(
            'AI-Powered Innovation',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">Our cutting-edge AI technology ensures seamless integration, simplifying complex processes and enhancing user experiences.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: FreeInvoiceCreation2
    },
    {
      label: 'Customization',
      detail: [
        {
          title: parsedContent(
            'Customization',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">We understand that every business is unique. Our platform allows you to tailor invoices to your brand's personality, setting you apart from the crowd.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: FreeInvoiceCreation3
    },
    {
      label: 'Security',
      detail: [
        {
          title: parsedContent(
            'Security',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">We prioritize your data's security, implementing state-of-the-art measures to safeguard your sensitive information.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: FreeInvoiceCreation4
    },
    {
      label: 'Responsive Support',
      detail: [
        {
          title: parsedContent(
            'Responsive Support',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">Have a question? Need assistance? Our dedicated customer support team is always ready to help you navigate the platform with ease.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: FreeInvoiceCreation5
    }
  ],
  ['online-invoice-software']: [
    {
      label: 'Sign Up',
      detail: [
        {
          title: parsedContent(
            'Sign Up',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Begin by signing up for a PayToMe account. You'll need to provide some basic information about your business, such as your company name, contact details, and preferred payment methods.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: isw_sign
    },
    {
      label: 'Customize Your Profile',
      detail: [
        {
          title: parsedContent(
            'Customize Your Profile',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Once you're logged in, you can personalize your profile by adding your company logo, choosing your brand colors, and setting up your preferred payment gateways. This step ensures that your invoices reflect your brand identity. Also, this free invoice software offers invoicing templates so that you can choose one as per your preference.</p>`,
            'text-twilight'
          )
        }
      ],
      img: isw_customizeProfile
    },
    {
      label: 'Create Invoices',
      detail: [
        {
          title: parsedContent(
            'Create Invoices',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Creating invoices online with PayToMe is a breeze. Simply click on the "Create Invoice" button and enter the necessary details, including the products or services provided, quantities, and prices. The software will automatically calculate the totals for you.</p>`,
            'text-twilight'
          )
        }
      ],
      img: isw_createInvoices
    },
    {
      label: 'Add Payment Options',
      detail: [
        {
          title: parsedContent(
            'Add Payment Options',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Incorporate payment options directly into your invoices. PayToMe allows you to insert payment links that lead directly to your chosen payment gateways. This means your clients can conveniently settle their bills with just a few clicks and you get paid faster.</p>`,
            'text-twilight'
          )
        }
      ],
      img: isw_addPaymentOptions
    },
    {
      label: 'Send Invoices',
      detail: [
        {
          title: parsedContent(
            'Send Invoices',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Once your invoice is ready, you can send it to your clients via email. PayToMe provides you with the option to include a personalized message and a note about the payment process. You can also choose to send automated reminders for overdue payments.</p>`,
            'text-twilight'
          )
        }
      ],
      img: isw_sendInvoices
    }
  ],
  ['online-invoice-payment']: [
    {
      label: 'Small Business or Startup ',
      detail: [
        {
          title: parsedContent(
            'Small Business or Startup ',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Small businesses and startups face numerous challenges, including cash flow management. PayToMe's online invoice payment solution helps small business owners automate invoicing, accept online payments, and track their financial health. </p>
            `,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicePayment1
    },
    {
      label: 'E-commerce Store or Retailer ',
      detail: [
        {
          title: parsedContent(
            'E-commerce Store or Retailer ',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">In the fast-paced world of e-commerce, efficient payment processing is essential for success. PayToMe allows online stores to easily accept payments, track sales, and offer customers convenient payment choices. </p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicePayment2
    },
    {
      label: 'Service Provider or Consultant ',
      detail: [
        {
          title: parsedContent(
            'Service Provider or Consultant ',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Service-based businesses rely on timely invoicing and payment collection to sustain operations. From consulting fees to project-based services, our platform simplifies financial management for service-oriented businesses.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicePayment3
    },
    {
      label: 'Subscription-Based Business ',
      detail: [
        {
          title: parsedContent(
            'Subscription-Based Business ',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Subscription-based businesses thrive on recurring revenue streams. Whether offering software subscriptions, membership services, or subscription boxes, our platform ensures consistent cash flow and customer satisfaction.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicePayment4
    },
    {
      label: 'Nonprofit Organization ',
      detail: [
        {
          title: parsedContent(
            'Nonprofit Organization ',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Nonprofit organizations take online invoice payment solution to accept online donations, manage donor contributions, and track fundraising efforts effectively. With customizable donation forms and secure payment processing, nonprofits can streamline fundraising campaigns and maximize donor engagement.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicePayment5
    }
  ],
  ['online-invoice']: [
    {
      label: 'Integrated Platform',
      detail: [
        {
          title: parsedContent(
            'Integrated Platform',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Invoicing is just one piece of the puzzle. Our online invoicing seamlessly integrates with our suite of payment features, creating a unified platform that simplifies your financial operations.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicePayment1
    },
    {
      label: 'Simplified Process',
      detail: [
        {
          title: parsedContent(
            'Simplified Process',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">In the fast-paced world of e-commerce, efficient payment processing is essential for success. PayToMe allows online stores to easily accept payments, track sales, and offer customers convenient payment choices. </p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicePayment2
    },
    {
      label: 'Service Provider or Consultant ',
      detail: [
        {
          title: parsedContent(
            'Service Provider or Consultant ',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Service-based businesses rely on timely invoicing and payment collection to sustain operations. From consulting fees to project-based services, our platform simplifies financial management for service-oriented businesses.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicePayment3
    },
    {
      label: 'Subscription-Based Business ',
      detail: [
        {
          title: parsedContent(
            'Subscription-Based Business ',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Subscription-based businesses thrive on recurring revenue streams. Whether offering software subscriptions, membership services, or subscription boxes, our platform ensures consistent cash flow and customer satisfaction.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicePayment4
    },
    {
      label: 'Nonprofit Organization ',
      detail: [
        {
          title: parsedContent(
            'Nonprofit Organization ',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Nonprofit organizations take online invoice payment solution to accept online donations, manage donor contributions, and track fundraising efforts effectively. With customizable donation forms and secure payment processing, nonprofits can streamline fundraising campaigns and maximize donor engagement.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicePayment5
    }
  ],
  ['online-invoicing-software']: [
    {
      label: 'Integrated Platform',
      detail: [
        {
          title: parsedContent(
            'Integrated Platform',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Invoicing is just one piece of the puzzle. Our online invoicing seamlessly integrates with our suite of payment features, creating a unified platform that simplifies your financial operations.</p>
            `,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicing1
    },
    {
      label: 'Simplified Process',
      detail: [
        {
          title: parsedContent(
            'Simplified Process',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Our user-friendly interface is designed for businesses of all sizes. Whether you're a solo entrepreneur or a growing enterprise, managing invoices becomes effortless, allowing you to focus on what truly matters. </p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicing2
    },
    {
      label: 'Unified Dashboard',
      detail: [
        {
          title: parsedContent(
            'Unified Dashboard',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">With PayToMe’s online invoice creator, your financial activities are combined into a single dashboard. No need to toggle between different platforms or applications. Seamlessly access invoices, payment records, and transaction histories all in one place, providing a comprehensive view of your financial health.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicing3
    },
    {
      label: 'Mobile Accessibility',
      detail: [
        {
          title: parsedContent(
            'Mobile Accessibility',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Manage your invoicing on the go. Our platform is accessible from any device with an internet connection. Whether you're at your desk, in a meeting, or on a business trip, you have the flexibility to oversee and manage your invoices anytime, anywhere.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicing4
    },
    {
      label: 'Secure Data Management ',
      detail: [
        {
          title: parsedContent(
            'Secure Data Management ',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Data security is non-negotiable. PayToMe uses robust security measures to protect your sensitive financial data. Our encryption protocols, authentication processes, and secure servers ensure that your information remains confidential and secure.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicing5
    },
    {
      label: 'Dedicated Support',
      detail: [
        {
          title: parsedContent(
            'Dedicated Support',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">At PayToMe, you're never alone. Our dedicated support team is here to assist you with any inquiries or challenges you might face. Whether it's technical support, troubleshooting, or guidance on best practices, we're just a message away.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicing6
    },
    {
      label: 'Constant Innovation',
      detail: [
        {
          title: parsedContent(
            'Constant Innovation',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">As technology evolves, so do we. PayToMe remains committed to staying at the forefront of fintech innovation. Expect continuous updates and enhancements to our online invoicing solution, ensuring you're always equipped with the latest tools and features.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicing7
    },
    {
      label: 'Transparent Pricing',
      detail: [
        {
          title: parsedContent(
            'Transparent Pricing',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">No hidden fees or surprises. PayToMe offers transparent pricing models that cater to businesses of various sizes. Choose a plan that aligns with your needs, and rest assured that you're getting value for your investment.</p>`,
            'text-twilight'
          )
        }
      ],
      img: OnlineInvoicing8
    }
  ],
  ['homepage_new']: [
    {
      label: parsedContent('Merchant Services <br /> Fees and PCI'),
      detail: [
        {
          title: parsedContent(
            'Merchant Account Fees',
            'text-blue-cyan text-xl lg:text-2xl font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-base lg:text-lg font-normal">Navigate the landscape of merchant fees with clarity. One-rate fees offer simplicity with a consistent charge per transaction. Monthly fees provide straightforward billing, while Interchange fees combine a fixed rate with variable interchange costs, tailored to your sales volume and business model. Understanding these can lead to significant savings and smarter financial planning for your business's payment processing needs.</p>
            `
          )
        },
        {
          title: parsedContent(
            'Merchant PCI Compliances',
            'text-blue-cyan text-xl lg:text-2xl font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-base lg:text-lg font-normal">Merchant PCI compliance is critical in safeguarding credit card information. Adhering to the Payment Card Industry Data Security Standard (PCI DSS) ensures businesses follow robust security measures to protect cardholder data. This not only fortifies trust with customers but also mitigates the risk of data breaches. Maintaining strict compliance is essential for secure transactions and the overall integrity of a company’s financial transactions.</p>`
          )
        }
      ],
      img: NewHomepage1
    },
    {
      label: parsedContent('Finance <br /> AR & AP Management'),
      detail: [
        {
          title: parsedContent(
            'Account Receivable',
            'text-blue-cyan text-xl lg:text-2xl font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-base lg:text-lg font-normal">Accounts Receivable (AR) management is vital for business liquidity. An Online Invoice Platform streamlines this process by allowing businesses to issue and track invoices digitally. Additionally, Text-to-Pay services enhance convenience by enabling customers to pay invoices via SMS, further accelerating payment cycles and improving cash flow. Together, these tools modernize AR operations, ensuring businesses maintain robust financial health.</p>
            `
          )
        },
        {
          title: parsedContent(
            'Account Payable',
            'text-blue-cyan text-xl lg:text-2xl font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-base lg:text-lg font-normal">Accounts Payable (AP) is crucial for managing business outflows and maintaining vendor relationships. An Invoice Management Platform facilitates this by automating the handling and approval of payable invoices, ensuring accuracy and timeliness. This technology simplifies workflows, reduces human error, and helps businesses meet payment deadlines efficiently. By leveraging such platforms, companies can optimize their financial operations and sustain good supplier relations.</p>
            `
          )
        }
      ],
      img: NewHomepage2
    },
    {
      label: parsedContent('Business <br /> Platforms & Products'),
      detail: [
        {
          title: parsedContent(
            'Financial Business Platforms',
            'text-blue-cyan text-xl lg:text-2xl font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-base lg:text-lg font-normal">A Financial Business Platform integrates Accounts Receivable (AR) and Accounts Payable (AP) functionalities to provide a comprehensive solution for managing a company's finances. These platforms streamline financial workflows, ensuring timely invoicing and payments, reducing manual errors, and improving overall efficiency. By automating these crucial financial operations, companies can enhance cash flow management, maintain strong vendor and customer relationships, and gain real-time insights into their financial health.</p>
            `
          )
        },
        {
          title: parsedContent(
            'Financial Business Products',
            'text-blue-cyan text-xl lg:text-2xl font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-base lg:text-lg font-normal">Financial Business Products like Text-to-Pay, Payment Links, E-Check, and ACH transfers offer versatile and efficient solutions for managing transactions. Text-to-Pay allows customers to make payments via SMS, enhancing convenience and speeding up collection times. Payment Links facilitate secure transactions by enabling one-click payments through personalized URLs. E-Checks and ACH transfers provide reliable and cost-effective alternatives to traditional paper checks, reducing processing times and banking fees. Together, these tools streamline payment processes, improve cash flow, and reduce operational overhead, making them invaluable for modern financial management.</p>
            `
          )
        }
      ],
      img: NewHomepage3
    }
  ],
  ['accounts_payable_services']: [
    {
      label: parsedContent('Start Guide'),
      detail: [
        {
          title: parsedContent(
            'What is Accounts Payable and its importance?',
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">Accounts Payable refers to the money a business owes to its suppliers or vendors for goods or services purchased on credit. It's crucial for managing cash flow and maintaining good relationships with suppliers.</p>
            `
          )
        },
        {
          title: parsedContent(
            "How can PayToMe's services benefit my business?",
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">PayToMe's Accounts Payable Services streamline bill payments, saving time and optimizing financial processes. With user-friendly software and dedicated support, businesses can enhance efficiency and accuracy in managing payables.</p>`
          )
        },
        {
          title: parsedContent(
            "What are the initial steps to get started with PayToMe's services?",
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">Getting started with PayToMe is simple. Sign up for an account, customize your settings, and upload your vendor information and invoices. Our team is available to assist you throughout the setup process.</p>`
          )
        }
      ],
      img: AccountPayableService1
    },
    {
      label: parsedContent('Software Use'),
      detail: [
        {
          title: parsedContent(
            "Is PayToMe's Account Payable Software easy to use?",
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">Yes, PayToMe's software is designed for user-friendliness. Its intuitive interface allows for easy navigation and quick adoption, even for users with minimal technical expertise.</p>
            `
          )
        },
        {
          title: parsedContent(
            "Can I customize PayToMe's Account Payable Software?",
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">Absolutely. PayToMe's software is highly customizable to meet the specific needs of your business. You can tailor settings, workflows, and reports to align with your unique processes and preferences.</p>
            `
          )
        },
        {
          title: parsedContent(
            'How does PayToMe ensure security for sensitive financial data?',
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">PayToMe employs robust security measures, including encryption and access controls, to safeguard sensitive financial information. Regular audits and compliance with industry standards further ensure the security and integrity of your data.</p>
            `
          )
        }
      ],
      img: AccountPayableService2
    },
    {
      label: parsedContent('Process Optimization'),
      detail: [
        {
          title: parsedContent(
            'How does PayToMe help optimize the accounts payable process?',
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">PayToMe automates invoice processing, streamlines approval workflows, and provides real-time visibility into payables, helping businesses reduce manual tasks and accelerate payment cycles.</p>
            `
          )
        },
        {
          title: parsedContent(
            "Can PayToMe's services help mitigate errors or fraud in accounts payable?",
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">Yes, PayToMe's solutions include built-in validation checks and approval controls to prevent errors and detect anomalies. By centralizing payment processes, businesses can also enhance oversight and accountability.</p>
            `
          )
        },
        {
          title: parsedContent(
            'Does PayToMe support compliance with accounting standards and regulations?',
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">PayToMe stays up-to-date with regulatory requirements and offers features to ensure compliance, such as audit trails, document retention, and adherence to industry best practices.</p>
            `
          )
        }
      ],
      img: AccountPayableService3
    }
  ],
  ['text_to_pay']: [
    {
      label: parsedContent('What is It and Benefits'),
      detail: [
        {
          title: parsedContent('What is Text-to-Pay', 'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'),
          content: parsedContent(
            `<p class="text-twilight font-normal">Text to Pay is a payment method that enables customers to make payments by sending a text message. It allows businesses to send payment requests via SMS, and customers can authorize the payment by replying with a simple text, providing a convenient and efficient way to complete transactions.</p>
            `
          )
        },
        {
          title: parsedContent(
            'What are the benefits of Text to Pay?',
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">Text to Pay allows customers to make payments via text message, offering benefits such as speed, accessibility, and security. With Text to Pay, transactions are completed quickly and easily, making it ideal for customers seeking a hassle-free  experience. Additionally, Text to Pay is accessible to all customers, including those without internet access, and ensures secure transactions.</p>`
          )
        }
      ],
      img: TextToPayImage1
    },
    {
      label: parsedContent('Use Cases'),
      detail: [
        {
          title: parsedContent('Retail', 'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'),
          content: parsedContent(
            `<p class="text-twilight font-normal">Retailers can use Text to Pay for quick and seamless checkout experiences, allowing customers to pay for their purchases directly through a text message.</p>
            `
          )
        },
        {
          title: parsedContent('Restaurants', 'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'),
          content: parsedContent(
            `<p class="text-twilight font-normal">Restaurants can leverage Text to Pay for contactless payment options, enabling customers to settle their bills without waiting for the check.</p>
            `
          )
        },
        {
          title: parsedContent('Service Providers', 'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'),
          content: parsedContent(
            `<p class="text-twilight font-normal">Service-based businesses such as salons, spas, and home service providers can utilize Text to Pay for invoicing and collecting payments.</p>
            `
          )
        },
        {
          title: parsedContent('Events and Ticketing', 'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'),
          content: parsedContent(
            `<p class="text-twilight font-normal">Event ticketing platforms can implement Text to Pay for ticket purchases and event registrations. Attendees can simply text a designated number to purchase tickets or register for events.</p>
            `
          )
        }
      ],
      img: TextToPayImage2
    },
    {
      label: parsedContent('Payment Method and Fees'),
      detail: [
        {
          title: parsedContent('Payment Methods', 'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'),
          content: parsedContent(
            `<p class="text-twilight font-normal">Text to Pay supports various payment methods, including credit/debit cards, bank transfers, and digital wallets like Apple Pay and Google Pay.</p>
            `
          )
        },
        {
          title: parsedContent('Fee Structure', 'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'),
          content: parsedContent(
            `<p class="text-twilight font-normal">Fees for Text to Pay transactions are determined by factors such as transaction volume, payment frequency, and chosen payment gateway.</p>
            `
          )
        },
        {
          title: parsedContent('Processing Fees', 'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'),
          content: parsedContent(
            `<p class="text-twilight font-normal">Merchants typically incur a small processing fee per transaction, which ranges from 1% to 3%.</p>
            `
          )
        },
        {
          title: parsedContent('Additional Charges', 'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'),
          content: parsedContent(
            `<p class="text-twilight font-normal">Additional charges may apply for premium features such as expedited payments or international transactions.</p>
            `
          )
        },
        {
          title: parsedContent('Cost-Benefit Analysis', 'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'),
          content: parsedContent(
            `<p class="text-twilight font-normal">Despite nominal fees, Text to Pay offers convenience and efficiency, making it an attractive payment solution for businesses.</p>
            `
          )
        }
      ],
      img: TextToPayImage3
    }
  ],
  ['online-checkbook']: [
    {
      label: parsedContent('What is It and Benefits'),
      detail: [
        {
          title: parsedContent('What is an online checkbook?', 'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'),
          content: parsedContent(
            `<p class="text-twilight font-normal">An online checkbook is a digital tool that allows users to manage their finances, and create, and send digital checks electronically, eliminating the need for traditional paper checks.</p>
            `
          )
        },
        {
          title: parsedContent(
            'What are the benefits of using an online checkbook?',
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">The benefits of digital checkbooks include enhanced convenience, improved efficiency, reduced paperwork, increased security, and seamless integration with banking services.</p>`
          )
        }
      ],
      img: benefits
    },
    {
      label: parsedContent('Use Cases'),
      detail: [
        {
          title: parsedContent(
            'How can I use an online checkbook?',
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">Online checkbooks can be used for various financial transactions such as paying bills, issuing payments to vendors or suppliers, reimbursing expenses, and conducting business transactions.</p>`
          )
        },
        {
          title: parsedContent(
            'Who can benefit from using an online checkbook?',
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">Businesses, freelancers, individuals, and organizations of all sizes can benefit from using an online checkbook to streamline their financial management processes and improve efficiency.</p>`
          )
        }
      ],
      img: useCases
    },
    {
      label: parsedContent('How to Send Digital Checks'),
      detail: [
        {
          title: parsedContent(
            'How do I send digital checks using an online checkbook?',
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">To send digital checks, log in to your online checkbook account, enter the recipient's information, specify the payment amount, and send the check electronically via email or through the online checkbook platform.</p>`
          )
        },
        {
          title: parsedContent(
            'What information do I need to send a digital check?',
            'text-blue-cyan font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal">You typically need the recipient's name, email address, payment amount, and any optional memo or reference information to send a digital check using an online checkbook. Some platforms may require additional details for security or verification purposes.</p>`
          )
        }
      ],
      img: sendDigitalChecks
    }
  ],
  ['online-payments']: [
    {
      label: parsedContent('Security and Reliability'),
      detail: [
        {
          title: parsedContent(
            'How does PayToMe ensure the security of online transactions?',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal body-content">PayToMe uses top-notch encryption technology to protect customer data and transactions. We also have strong security measures in place to prevent unauthorized access.</p>
            `
          )
        },
        {
          title: parsedContent(
            'What measures does PayToMe take to protect sensitive customer data?',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal body-content">At PayToMe, we follow strict rules and laws to keep customer information safe. We update our security systems regularly to protect against new threats.</p>`
          )
        }
      ],
      img: Security
    },
    {
      label: parsedContent('Convenience and Flexibility'),
      detail: [
        {
          title: parsedContent(
            'What payment methods does PayToMe support for online transactions?',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">PayToMe offers a wide range of payment methods, including credit/debit cards, bank transfers, digital wallets (such as Apple Pay and Google Pay), and alternative payment solutions, ensuring convenience for both merchants and customers.</p>`
          )
        },
        {
          title: parsedContent(
            "How easy is it to integrate PayToMe's online payment solutions into my existing business operations?",
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">PayToMe offers seamless integration options, user-friendly APIs, and plugins for popular e-commerce platforms. The dedicated support team assists businesses throughout the integration process, ensuring minimal disruption to operations.</p>`
          )
        }
      ],
      img: Convenience
    },
    {
      label: parsedContent('Support and Innovation'),
      detail: [
        {
          title: parsedContent(
            'What kind of customer support does PayToMe provide for its online payment solutions?',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">PayToMe offers excellent customer support, including live chat, email support, and phone assistance, available 24/7. Our highly trained and responsive support team ensures quick solutions to any queries or issues.</p>`
          )
        },
        {
          title: parsedContent(
            'How does PayToMe stay updated with the latest trends and technologies in online payments?',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">PayToMe is committed to continuous innovation and stays up-to-date of the latest developments in the online payments industry. We invest in research and development initiatives to enhance our solutions and provide our clients with cutting-edge payment technologies.</p>`
          )
        }
      ],
      img: Support
    }
  ],
  ['online-invoicing']: [
    {
      label: parsedContent('Detailed Report'),
      detail: [
        {
          title: parsedContent('Online Invoice', ''),
          content: parsedContent(
            `<p class="body-content">PayToMe’s digital invoices offer a modern solution to traditional billing methods. With our advanced free invoice generator software, you can create, send, and track invoices electronically, eliminating paper-based inefficiencies.</p>
             <p class="body-content">Save time and money with streamlined invoicing.</p>
            `
          )
        },
        {
          title: parsedContent('Detailed Report', ''),
          content: parsedContent(
            `<p class="">Monitor your business's performance to enable informed decision-making.</p>
            <p class="">Review the dashboard for immediate insights presented in colorful graphs and charts or generate over 30 real-time business reports.</p>
            `,
            ``
          )
        }
      ],
      img: DetailedReport
    },
    {
      label: parsedContent('Payment Link'),
      detail: [
        {
          title: parsedContent('Text to Pay', ''),
          content: parsedContent(
            `<p>Text to Pay is a convenient payment solution that allows customers to settle their bills via SMS. With just a simple text message, your clients can make payments securely and swiftly.</p>
             <p>PayToMe’s patented text-to-pay technology simplifies payments! Just send an invoice link through SMS!</p>
            `
          )
        },
        {
          title: parsedContent('Payment Link', ''),
          content: parsedContent(
            `<p>Payment links are a hassle-free way to request payments from your customers. Text payment link via email or messaging apps, and your clients can make payments with ease, from anywhere and any time!</p>
             <p>PayToMe’s patented text-2-pay technology allows you to send direct payment link to your clients.</p>
            `
          )
        }
      ],
      img: InvPaymentLink
    },
    {
      label: parsedContent('Invoice for Small Business'),
      detail: [
        {
          title: parsedContent('Invoice for Small Business', ''),
          content: parsedContent(
            `<p>Create professional invoices effortlessly with our easy-to-use invoicing tools. Customize templates, track payments, and manage your finances with ease. Simplify your billing process and ensure timely payments, enhancing your cash flow and helping your small business thrive.</p>
             <p>Our invoicing solution is perfect for small business owners looking for efficient and reliable online invoicing options.</p>            
            `
          )
        },
        {
          title: parsedContent('Invoice Template', ''),
          content: parsedContent(
            `<p>Invoice Template Enhance the professional appearance of your invoices with our diverse selection of invoice templates designed for various countries.</p>
             <p>PayToMe’s patented text-to-pay technology simplifies payments! Just send an invoice link through SMS!</p>
            `
          )
        }
      ],
      img: InvoiceForSmallBusiness
    }
  ],
  ['bank-account-verification']: [
    {
      label: parsedContent('Basics of Bank Verification'),
      detail: [
        {
          title: parsedContent(
            'What is bank account verification?',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal body-content">Bank verification, also known as bank account verification, is the process to confirm the authenticity and ownership of a bank account. It involves verifying essential details of account owner. These include the account number, account holder's name, and account type (e.g., checking or savings). This verification ensures the accuracy of financial transactions, reduces fraud risks, and enhances the security of online banking.</p>
            `
          )
        },
        {
          title: parsedContent(
            'What is bank account verification used for?',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal body-content">Bank verification is essential for various financial transactions, including electronic funds transfers, ACH payments, and online banking activities. It validates bank account information to minimize errors and ensure the legitimacy of transactions. This process is integral to the onboarding process for new customers and is utilized across different platforms like e-commerce websites, personal finance apps, and investment platforms.</p>`
          )
        }
      ],
      img: basicsOfBank
    },
    {
      label: parsedContent('Instant and Auto Bank Verification'),
      detail: [
        {
          title: parsedContent(
            'Automatic Bank Validation',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">This method verifies a bank account details against a database to ensure accuracy and security, commonly used for offline payments and significant business transactions.</p>`
          )
        },
        {
          title: parsedContent(
            'Instant Bank Verification',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">Through APIs like PayToMe.co's Financial Connections, customers securely log into their online banks via Auth, replacing passwords with tokens for swift and secure access. This method is utilized across various platforms including online marketplaces, e-commerce sites, personal finance apps, and investment platforms, ensuring both convenience and safety for users.</p>`
          )
        }
      ],
      img: instantAutoBank
    },
    {
      label: parsedContent('Micro Deposit & Document Verification'),
      detail: [
        {
          title: parsedContent(
            'Micro Deposit verification',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">PayToMe.co initiates small monetary transfers to the specified account, and the account holder confirms the received amounts, commonly preferred by traditional financial institutions like banks and credit unions.</p>`
          )
        },
        {
          title: parsedContent(
            'Document-based verification',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">Account holders submit paper or electronic documents like voided checks or bank statements to validate account status and ownership, commonly used in scenarios like mortgage and auto loans, ensuring thorough verification processes. Trust PayToMe.co for streamlined document-based verification of financial data.</p>`
          )
        }
      ],
      img: microDeposit
    }
  ],
  ['credit-card-terminal']: [
    {
      label: parsedContent('Secure & User Friendly'),
      detail: [
        {
          title: parsedContent(''),
          content: parsedContent(
            `
            <ul>
                <li>Every transaction is a seamless process with PayToMe ensuring secure payments. Our credit card transaction processing machines are equipped with modern security features, ensuring each payment is processed with care, giving you and your customers peace of mind.</li>
            </ul>
            `
          )
        },
        {
          title: parsedContent(''),
          content: parsedContent(
            `
            <ul>
                <li>Our credit card machines are designed to be intuitive and easy to set up, so you can start accepting payments smoothly without any interruptions.</li>
            </ul>
            `
          )
        }
      ],
      img: creditCardTerminal1
    },
    {
      label: parsedContent('Transparent Pricing'),
      detail: [
        {
          title: parsedContent(''),
          content: parsedContent(
            `
            <ul>
                <li>Say goodbye to unexpected fees. With PayToMe, you'll benefit from competitive rates and transparent pricing. We believe in honesty and integrity in our billing practices, ensuring you only pay for what you need.</li>
            </ul>
            `
          )
        },
        {
          title: parsedContent(''),
          content: parsedContent(
            `
            <ul>
                <li>Our dedicated 24/7 customer support team is always available to help ensure your business operations run smoothly. Whenever you have questions or need assistance, we're here to help you keep your business moving forward.</li>
            </ul>
            `
          )
        }
      ],
      img: creditCardTerminal2
    },
    {
      label: parsedContent('Solutions and Integrations'),
      detail: [
        {
          title: parsedContent(''),
          content: parsedContent(
            `
            <ul>
                <li>Your business is unique, and your payment processing solution should be too. PayToMe offers credit card processing solutions designed to align perfectly with your business requirements.</li>
            </ul>
            `
          )
        },
        {
          title: parsedContent(''),
          content: parsedContent(
            `
            <ul>
                <li>Maximize efficiency with PayToMe's credit card reader machine that effortlessly integrates with leading POS systems. Simplify your operations, manage transactions more effectively, and provide a smoother checkout experience for your customers.</li>
            </ul>
            `
          )
        }
      ],
      img: creditCardTerminal3
    }
  ],
  ['kyc-verification']: [
    {
      label: parsedContent('Unmatched Efficiency'),
      detail: [
        {
          title: parsedContent(
            'Streamlined Process',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal body-content">Our platform accelerates the KYC verification process, allowing for rapid onboarding of customers. This efficiency reduces waiting times and enhances customer satisfaction.</p>
            `
          )
        },
        {
          title: parsedContent(
            'User-Friendly Interface',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal body-content">Designed with simplicity in mind, our interface ensures that both businesses and their customers can easily navigate the verification process without complications.</p>`
          )
        }
      ],
      img: UnmatchedEfficiency
    },
    {
      label: parsedContent('Enhanced Security'),
      detail: [
        {
          title: parsedContent(
            'Regulatory Adherence',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">PayToMe.co is committed to compliance, ensuring that your business meets all necessary regulatory standards without the need for extensive internal resources.</p>`
          )
        },
        {
          title: parsedContent(
            'Data Protection',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">We ensure top-tier security measures to protect both your and your customers' information during the verification process. Our encryption protocols and strict data protection policies provide peace of mind.</p>`
          )
        }
      ],
      img: enhancedSecurity
    },
    {
      label: parsedContent('Cost-Effective'),
      detail: [
        {
          title: parsedContent(
            'Reduced Operational Costs',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">Leveraging PayToMe.co's KYC solution can significantly lower your business's operational expenses associated with customer verification, freeing up resources for other critical areas of your operation.</p>`
          )
        },
        {
          title: parsedContent(
            'Scalable Solution for Growth',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">PayToMe.co's platform is designed to grow with your business. It can handle increasing verification demands without sacrificing efficiency or user experience. Whether your business is a small startup or a well-established financial institution, our solution is adaptable to your needs.</p>`
          )
        }
      ],
      img: costEffective
    }
  ],
  ['kyb-verification']: [
    {
      label: parsedContent('Business Verification Definition'),
      detail: [
        {
          title: parsedContent(
            'What is business verification?',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal body-content">PayToMe's KYB Verification process ensures the legitimacy of businesses and ownership structures, conducting due diligence to prevent illegal activities. Our solution applies AML regulations to combat financial crimes and ensure compliance with confidence.</p>
            `
          )
        },
        {
          title: parsedContent(
            'What are business verification services?',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal body-content">PayToMe's business verification services confirm the legality of companies and assess the risk of money laundering and fraud. We assist organizations in meeting their KYB obligations by gathering essential verifying information.</p>`
          )
        }
      ],
      img: businessVerification
    },
    {
      label: parsedContent('Benefits of KYB Verifications'),
      detail: [
        {
          title: parsedContent(
            'Why is business verification necessary?',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">Our KYB solutions assist companies in verifying legitimate and compliant entities, essential for meeting regulatory requirements such as anti-money laundering, counter-terrorist financing laws, and fraud prevention.</p>`
          )
        },
        {
          title: parsedContent(
            'What is the benefit of business verification?',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">Company verification goes beyond legal compliance, fostering trust in B2B transactions. By confirming a business partner's legitimacy, companies can make informed decisions, reduce risks, and establish lasting partnerships.</p>`
          )
        }
      ],
      img: benefitsOfKyb
    },
    {
      label: parsedContent('Verify from Original Source'),
      detail: [
        {
          title: parsedContent(
            'Verify From Original Source',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">PayToMe verifies documents such as bank statements, bank accounts, utility bills, licenses, incorporation documents, tax returns, etc., directly from the original web source. PayToMe aids in de-risking business operations by eliminating fake and stolen documents, reducing the risk of money laundering.</p>`
          )
        },
        {
          title: parsedContent(
            'Global Coverage',
            'text-blue-cyan section-body-title font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">PayToMe's acclaimed document verification solution is versatile across industries and geographic locations. PayToMe can promptly authenticate any document from both private and government websites with user consent.</p>`
          )
        }
      ],
      img: verifyFromOriginal
    }
  ],
  ['identity-verification']: [
    {
      label: parsedContent('Streamlined Compliance Measures'),
      detail: [
        {
          title: parsedContent(
            'Comprehensive Compliance Checks:',
            'text-blue-cyan section-subtitle font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal body-content">Conduct thorough KYC (Know Your Customer) and AML (Anti-Money Laundering) screenings to ensure regulatory compliance and mitigate financial risks.</p>
            `
          )
        },
        {
          title: parsedContent(
            'Automated Risk Assessment:',
            'text-blue-cyan section-subtitle font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight font-normal body-content">Take advantage of our robust identity verification system to assess customer risk profiles and flag suspicious activities.</p>`
          )
        }
      ],
      img: streamlinedCompliance
    },
    {
      label: parsedContent('Enhanced Security Measures'),
      detail: [
        {
          title: parsedContent(
            'Multi-Factor Authentication:',
            'text-blue-cyan section-subtitle font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">Implement multi-factor authentication methods) verification to strengthen account security and prevent unauthorized access.</p>`
          )
        },
        {
          title: parsedContent(
            'Continuous Monitoring:',
            'text-blue-cyan section-subtitle font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">Use real-time monitoring systems to quickly detect and respond to potential security threats, reducing the chances of data breaches and fraud.</p>`
          )
        }
      ],
      img: enhancedSecurityMeasures
    },
    {
      label: parsedContent('Customer-Centric Approach'),
      detail: [
        {
          title: parsedContent(
            'Seamless User Experience:',
            'text-blue-cyan section-subtitle font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">Make the user's life easier by providing a smooth process to verify their identity. This will create a positive experience for the customer and keep them satisfied and coming back.</p>`
          )
        },
        {
          title: parsedContent(
            'Transparent Communication:',
            'text-blue-cyan section-subtitle font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight body-content font-normal">Keep customers informed about what they need to verify their identity and how to do it. This will help them trust that we're keeping their information safe.</p>`
          )
        }
      ],
      img: customerCentricApproach
    }
  ]
}
